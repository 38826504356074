<template>
  <div>
    <b-card v-if="isLoading && !fetchComplete" class="text-center">
        <b-spinner>Tunggu...</b-spinner>
    </b-card>
    <b-card v-if="!isLoading && fetchComplete">
      <event-booth-question-add-content :event-booth-id="eventBoothId" :isEdit="isEdit" class="mt-2 pt-75"/>
    </b-card>

    <b-card v-if="!isLoading && fetchComplete && isEdit">
      <event-booth-question-response-content :event-booth-id="eventBoothId" class="mt-2 pt-75"/>
    </b-card>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import EventBoothQuestionAddContent from './EventBoothQuestionAddContent.vue'
import EventBoothQuestionResponseContent from './EventBoothQuestionResponseContent.vue'
import router from '@/router'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    EventBoothQuestionAddContent,
    EventBoothQuestionResponseContent,
  },
  data() {
    return {
      eventBoothId: router.currentRoute.params.id,
      isEdit: false,
      isLoading: false,
      fetchComplete: false,
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true

      this.$http.post('/admin/v1/feedback_questions_single', {
        event_id: null,
        event_booth_id: this.eventBoothId
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            if(currentData.questions.length > 0) {
                this.isEdit = true
              }
          }

          this.fetchComplete = true
          this.isLoading = false
       })
    }
  },
  created() {
    this.fetchData()

  }
}
</script>